import React, { useEffect, useState } from "react";
import "./Overview_card.css";
import { Button, Table } from "react-bootstrap";
import { faLock, faPlay, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getMarkets } from "../../../../../../utils/apis/games";

function Overview_card({
  event,
  seteventDetailsId,
  seteventDetails,
  setActive,
}) {
  // const [market, setmarket] = useState([]);

  // const Markets = async (id) => {
  //   const res = await getMarkets(id);
  //   setmarket(res);
  // };
  // useEffect(() => {
  //   if (event) {
  //     Markets(event?.id);
  //   }
  // }, [event]);

  // useEffect(() => {
  //   if (event) {
  //     Markets(event?.id);
  //     const intervalId = setInterval(() => {
  //       Markets(event?.id);
  //     }, 5000);

  //     return () => clearInterval(intervalId);
  //   }
  // }, [event]);

  return (
    <>
      <section className="Overview_card">
        <div className="Overview_card_holder">
          <div className="table-container">
            <Table responsive className="match-table mb-0">
              <thead>
                <tr>
                  <th colSpan="2" className="text-start">
                    {event?.description}
                  </th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th
                    className="text-center btn"
                    onClick={() => {
                      setActive("second");
                      seteventDetails(event);
                      seteventDetailsId(event?.id);
                      // console.log(event?.id);
                    }}
                  >
                    Event Details
                  </th>
                  <th className="text-center">
                    <FontAwesomeIcon className="start-icon" icon={faStar} />
                  </th>
                </tr>
              </thead>
              {/* <tbody>
                {market?.map((val, index) => (
                  <tr>
                    <td className="time-cell">
                      {event?.clock?.minutes}:{event?.clock?.seconds}
                    </td>
                    <td>{val?.description}</td>
                    <td className="text-center">
                      <FontAwesomeIcon className="play-icon" icon={faPlay} />
                    </td>
                    <td className="text-center number-box active">1.25</td>
                    {val?.outcomes?.map((outcome, index) => (
                      <td className="text-center number-box" key={index}>
                        {outcome?.consolidatedPrice?.currentPrice?.format}
                      </td>
                    ))}
                    
                    <td className="text-center">0</td>
                    <td className="text-center">
                      <FontAwesomeIcon className="start-icon" icon={faStar} />
                    </td>
                  </tr>
                ))}
                
              </tbody> */}
            </Table>
          </div>
        </div>
      </section>
    </>
  );
}

export default Overview_card;
