import React, { useState } from "react";
import "./exchangeprofile.css";
import "./exchangeprofile2.css";
import Form from "react-bootstrap/Form";
import Successfull_Lottie from "../../Animation/Successfull_Lottie/Successfull_Lottie";
const ChangePassword = () => {
  const [showNote, setShowNote] = useState(false);

  const handleClick = () => {
    setShowNote(true);
    // setTimeout(() => {
    //   setShowNote(false);
    // }, 3000);
  };
  return (
    <>
      <section className="exchangeprofilemain-sec">
        <div className="secttiill">
          <div className="titlediv">
            <p className="title">Change Password</p>
          </div>
        </div>
        <div className="Change">
          <div className="Change-Password">
            <div className="row">
              <div className="col-md-6">
                <div className="main main mb-lg-5  mb-4">
                  <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    placeholder="Enter Current Password"
                  />
                </div>
                <div className="main main mb-lg-5  mb-4">
                  <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    placeholder="Enter New Password"
                  />
                </div>
                <div className="main">
                  <Form.Control
                    type="password"
                    id="inputPassword5"
                    aria-describedby="passwordHelpBlock"
                    placeholder="Enter New Password to Confirm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="password">
            <div className="secttiill">
              <div className="titlediv" onClick={handleClick}>
                <p className="title">Change Password</p>
              </div>
              {showNote && (
                <div className="note-main slide-left">
                  <div className="note">
                    <div className="note-content">
                      <Successfull_Lottie />
                      <p className="pass">Password Changed Successfully</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
