import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <>
      <section className="Footer">
        <div className="container">
          <div className="footer-logo-holder">
            <img
              className="footer-logo-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/Images/Header/betzone-logo.png"
              }
              alt="footer-logo-img"
            />
          </div>

          <div className="footer_link_container">
            <div className="row">
              <div className="col-md-4 col-sm-6 first-div">
                <div className="link-holder">
                  <div className="footer-heading-holder">
                    <h3>Quick Links</h3>
                  </div>

                  <div className="link-text-holder">
                    <p>About Us</p>
                  </div>

                  <div className="link-text-holder">
                    <p>Terms of Use</p>
                  </div>

                  <div className="link-text-holder">
                    <p>Privacy Policy</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 second-div">
                <div className="link-holder">
                  <div className="footer-heading-holder">
                    <h3>Support Links</h3>
                  </div>

                  <div className="link-text-holder">
                    <p>Help</p>
                  </div>

                  <div className="link-text-holder">
                    <p>FAQs</p>
                  </div>

                  <div className="link-text-holder">
                    <p>Contact Us</p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 third-div">

                <div className="link-holder">
                  <div className="footer-heading-holder">
                    <h3>Social Media Links</h3>
                  </div>

                  <div className="footer-social-link-holder">
                    <div className="social-link-holder">
                      <img
                        className="social-link-lmg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Footer/facebook.png"
                        }
                        alt="social-link-lmg"
                      />
                    </div>


                    <div className="social-link-holder">
                      <img
                        className="social-link-lmg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Footer/instagram.png"
                        }
                        alt="social-link-lmg"
                      />
                    </div>


                    <div className="social-link-holder">
                      <img
                        className="social-link-lmg"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Images/Footer/youtube.png"
                        }
                        alt="social-link-lmg"
                      />
                    </div>
                  </div>

                  <div className="footer-mail-text">
                    <p>support@warrisports.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
