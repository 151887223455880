import React from "react";
import "./Overview.css";
import Overview_card from "./Overview_card/Overview_card";

function Overview({ allLive, seteventDetailsId, seteventDetails, setActive }) {
  return (
    <>
      <section className="Overview">
        {allLive?.events?.map((event, index) => {
          return (
            <div className="Overview_card_content_holder mb-4" key={index}>
              <Overview_card
                event={event}
                seteventDetailsId={seteventDetailsId}
                seteventDetails={seteventDetails}
                setActive={setActive}
              />
            </div>
          );
        })}

        {/* <div className="Overview_card_content_holder mb-4">
          <Overview_card />
        </div>

        <div className="Overview_card_content_holder mb-4">
          <Overview_card />
        </div>

        <div className="Overview_card_content_holder mb-4">
          <Overview_card />
        </div>

        <div className="Overview_card_content_holder mb-4">
          <Overview_card />
        </div> */}
      </section>
    </>
  );
}

export default Overview;
