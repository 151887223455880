import React, { useEffect, useState } from "react";
import "./Event_details.css";
import Event_details_card from "./Event_details_card/Event_details_card";
import { getMarkets } from "../../../../../utils/apis/games";

function Event_details({ eventDetailsId, eventDetails }) {
  const [market, setmarket] = useState([]);

  const Markets = async (id) => {
    const res = await getMarkets(id);
    setmarket(res);
  };
  useEffect(() => {
    if (eventDetailsId) {
      Markets(eventDetailsId);
      const intervalId = setInterval(() => {
        Markets(eventDetailsId);
      }, 5000);

      return () => clearInterval(intervalId);
    }

    // if (eventDetailsId) {
    //   Markets(eventDetailsId);
    // }
  }, [eventDetailsId]);

  // console.log(market?.length);
  console.log(eventDetailsId);

  return (
    <>
      <section className="Event_details">
        <div className="Event_details_content_card mb-4">
          <Event_details_card event={eventDetails} market={market} />
        </div>
      </section>
    </>
  );
}

export default Event_details;
