import React from "react";
import "./Event_details_card.css";
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
function Event_details_card({ event, market }) {
  return (
    <>
      <section className="Event_details_card">
        <div className="Event_details_card_holder">
          <section className="details-heading-holder">
            <div className="ball-img-holder">
              <img
                className="ball-img"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Images/Home/Banner/banner-img-1.png"
                }
                alt="ball-img"
              />
            </div>

            <div className="heading-holder">
              <div>
                <h4>Live Football</h4>
                <h2>{event?.description}</h2>
              </div>
            </div>
          </section>

          <section className="live-list-box-holder">
            <div className="game-vdo-score-holder">
              <div className="text-holder">
                <p>{event?.opponents[0]?.description}</p>
              </div>

              <div className="text-holder">
                <p>
                  {event?.scores?.score?.map((point, index) => (
                    <>
                      <span> {point?.points} </span>
                      {event?.scores?.score?.length != index + 1
                        ? event?.scores?.period
                        : null}
                    </>
                  ))}
                </p>
                <p className="time-text">
                  {event?.clock?.minutes} :{event?.clock?.seconds} min
                </p>
              </div>

              <div className="text-holder">
                <p>{event?.opponents[1]?.description}</p>
              </div>
            </div>

            <div className="list-holder">
              <div>
                <ul>
                  <li>{event?.currentPeriod}</li>

                  {/* <li>04:23 - End of First Half</li>

                  <li>00:00 - Start of First Half</li>

                  <li>00:00 - Start of Event</li> */}
                </ul>
              </div>
            </div>
          </section>

          <section className="match-accordian-holder">
            <Accordion defaultActiveKey={0}>
              {market?.length ? (
                market?.map((val, index) => (
                  <Accordion.Item eventKey={0}>
                    <Accordion.Header>
                      <p>
                        {val?.description} - {val?.period?.fullDescription}
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="accordian-content">
                        <div className="row">
                          {val?.outcomes?.map((outcome, index) => (
                            <div className="col-md-4 border-right-holder">
                              <div className="text-content-holder">
                                <p>{outcome?.description} </p>
                                <div className="time-holder">
                                  <p>
                                    {
                                      outcome?.consolidatedPrice?.currentPrice
                                        ?.format
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              ) : (
                <h2>No open markets for this event.</h2>
              )}

              {/* <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <p>Win/Draw/Win - Match</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="accordian-content">
                    <div className="row">
                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordian-content">
                    <div className="row">
                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordian-content">
                    <div className="row">
                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordian-content">
                    <div className="row">
                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item> */}

              {/* <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <p>Win/Draw/Win - First Half</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="accordian-content">
                    <div className="row">
                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <p>Asian Handicap - Match</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="accordian-content">
                    <div className="row">
                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <p>Asian Handicap - First Half</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="accordian-content">
                    <div className="row">
                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>AC Milan (Raul)</p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 border-right-holder">
                        <div className="text-content-holder">
                          <p>
                            AC Milan (Raul){" "}
                            <span className="green-text">+0.12</span>
                          </p>
                          <div className="time-holder">
                            <p>05:00</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="text-content-holder">
                          <p>
                            AC Milan (Raul){" "}
                            <span className="red-text">-0.12</span>
                          </p>
                          <div className="time-holder lock-time-holder">
                            <p>
                              <FontAwesomeIcon
                                className="lock-icon"
                                icon={faLock}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
          </section>
        </div>
      </section>
    </>
  );
}

export default Event_details_card;
