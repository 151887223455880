import React from "react";
import "./Banner.css";
import { Button } from "react-bootstrap";
import { Pagination } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

function Banner() {
  return (
    <>
      <section className="Banner">
        <Swiper
          className="banner-swiper mySwiper"
          modules={[Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          loop={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide>
            <div className="banner-holder">
              <div className="banner-img-holder">
                <img
                  className="banner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/banner-img-1.png"
                  }
                  alt="banner-img"
                />
              </div>

              <div className="left-bottom-corner-img-holder">
                <img
                  className="left-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/left-bottom-corner.png"
                  }
                  alt="left-bottom-corner-img"
                />
              </div>

              <div className="right-bottom-corner-img-holder">
                <img
                  className="right-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/right-bottom-corner.png"
                  }
                  alt="right-bottom-corner-img"
                />
              </div>
              <div className="banner-content-holder">
                <div className="container">
                  <div className="text-holder">
                    <h4>Bet & Win Today!</h4>
                    <h2>Warri Sports Bets Peer 2 Peer</h2>
                    <p>
                      The fastest, easiest way to bet on sports. <br /> Choose
                      on which team to bet and win
                    </p>
                  </div>

                  <div className="btn-holder">
                    <Button className="download_btn">Get Started Now!</Button>
                  </div>
                </div>
              </div>

              <div className="overlay-holder"></div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="banner-holder">
              <div className="banner-img-holder">
                <img
                  className="banner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/banner-img-1.png"
                  }
                  alt="banner-img"
                />
              </div>

              <div className="left-bottom-corner-img-holder">
                <img
                  className="left-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/left-bottom-corner.png"
                  }
                  alt="left-bottom-corner-img"
                />
              </div>

              <div className="right-bottom-corner-img-holder">
                <img
                  className="right-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/right-bottom-corner.png"
                  }
                  alt="right-bottom-corner-img"
                />
              </div>
              <div className="banner-content-holder">
                <div className="container">
                  <div className="text-holder">
                    <h4>Bet & Win Today!</h4>
                    <h2>Warri Sports Bets Peer 2 Peer</h2>
                    <p>
                      The fastest, easiest way to bet on sports. <br /> Choose
                      on which team to bet and win
                    </p>
                  </div>

                  <div className="btn-holder">
                    <Button className="download_btn">Get Started Now!</Button>
                  </div>
                </div>
              </div>

              <div className="overlay-holder"></div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="banner-holder">
              <div className="banner-img-holder">
                <img
                  className="banner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/banner-img-1.png"
                  }
                  alt="banner-img"
                />
              </div>

              <div className="left-bottom-corner-img-holder">
                <img
                  className="left-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/left-bottom-corner.png"
                  }
                  alt="left-bottom-corner-img"
                />
              </div>

              <div className="right-bottom-corner-img-holder">
                <img
                  className="right-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/right-bottom-corner.png"
                  }
                  alt="right-bottom-corner-img"
                />
              </div>
              <div className="banner-content-holder">
                <div className="container">
                  <div className="text-holder">
                    <h4>Bet & Win Today!</h4>
                    <h2>Warri Sports Bets Peer 2 Peer</h2>
                    <p>
                      The fastest, easiest way to bet on sports. <br /> Choose
                      on which team to bet and win
                    </p>
                  </div>

                  <div className="btn-holder">
                    <Button className="download_btn">Get Started Now!</Button>
                  </div>
                </div>
              </div>

              <div className="overlay-holder"></div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="banner-holder">
              <div className="banner-img-holder">
                <img
                  className="banner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/banner-img-1.png"
                  }
                  alt="banner-img"
                />
              </div>

              <div className="left-bottom-corner-img-holder">
                <img
                  className="left-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/left-bottom-corner.png"
                  }
                  alt="left-bottom-corner-img"
                />
              </div>

              <div className="right-bottom-corner-img-holder">
                <img
                  className="right-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/right-bottom-corner.png"
                  }
                  alt="right-bottom-corner-img"
                />
              </div>
              <div className="banner-content-holder">
                <div className="container">
                  <div className="text-holder">
                    <h4>Bet & Win Today!</h4>
                    <h2>Warri Sports Bets Peer 2 Peer</h2>
                    <p>
                      The fastest, easiest way to bet on sports. <br /> Choose
                      on which team to bet and win
                    </p>
                  </div>

                  <div className="btn-holder">
                    <Button className="download_btn">Get Started Now!</Button>
                  </div>
                </div>
              </div>

              <div className="overlay-holder"></div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="banner-holder">
              <div className="banner-img-holder">
                <img
                  className="banner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/banner-img-1.png"
                  }
                  alt="banner-img"
                />
              </div>

              <div className="left-bottom-corner-img-holder">
                <img
                  className="left-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/left-bottom-corner.png"
                  }
                  alt="left-bottom-corner-img"
                />
              </div>

              <div className="right-bottom-corner-img-holder">
                <img
                  className="right-bottom-corner-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Home/Banner/right-bottom-corner.png"
                  }
                  alt="right-bottom-corner-img"
                />
              </div>
              <div className="banner-content-holder">
                <div className="container">
                  <div className="text-holder">
                    <h4>Bet & Win Today!</h4>
                    <h2>Warri Sports Bets Peer 2 Peer</h2>
                    <p>
                      The fastest, easiest way to bet on sports. <br /> Choose
                      on which team to bet and win
                    </p>
                  </div>

                  <div className="btn-holder">
                    <Button className="download_btn">Get Started Now!</Button>
                  </div>
                </div>
              </div>

              <div className="overlay-holder"></div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
}

export default Banner;
