import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import Set_Password from "../Set_Password/Set_Password";
import { Link, useNavigate } from "react-router-dom";

function Reset_Password(props) {
  const navigate = useNavigate();
  const [passwordModalShow, setPasswordModalShow] = React.useState(false);
  

  const handleSetPasswordClick = () => {
    props.onHide();
    setPasswordModalShow(true);
  };



  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Login_Modal Common_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

<div className="modal-bg-img">
          <img
            className="modal-bg"
            src={
              process.env.PUBLIC_URL +
              "/assets/Images/Lohin_Modal/warri-sports.png"
            }
            alt="modal-bg"
          />
        </div>
        <Modal.Body>
          <div className="back-icon-holder" >
            <FontAwesomeIcon className="back-icon" icon={faAngleLeft} />
          </div>
          <div className="modal_heading">
            <h4>Reset Password</h4>
          </div>

          <div className="form-holder">
            <Form>
              <Form.Group className="form-field" controlId="">
                <Form.Label>Enter Email or Mobile Number</Form.Label>
                <Form.Control
                  type="phone"
                  placeholder="Enter Email or Mobile Number"
                />
              </Form.Group>

              <Button className="common-btn" onClick={handleSetPasswordClick}>
                Submit
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Set_Password
        show={passwordModalShow}
        onHide={() => setPasswordModalShow(false)}
      />
    </>
  );
}

export default Reset_Password;
