import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Success_Modal from "../../Common_Component/Common_Modal/Success_Modal/Success_Modal";

function Set_Password(props) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [successShow, setSuccessShow] = React.useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const navigate = useNavigate();

  const handleSubmitClick = () => {
    props.onHide();
    setSuccessShow(true);
    
    // Set timeout to close the modal and navigate after 3 seconds
    setTimeout(() => {
      setSuccessShow(false);
      navigate("/");
    }, 3000);
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Login_Modal Common_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

<div className="modal-bg-img">
          <img
            className="modal-bg"
            src={
              process.env.PUBLIC_URL +
              "/assets/Images/Lohin_Modal/warri-sports.png"
            }
            alt="modal-bg"
          />
        </div>
        <Modal.Body>
          <div className="back-icon-holder" >
            <FontAwesomeIcon className="back-icon" icon={faAngleLeft} />
          </div>
          <div className="modal_heading">
            <h4>Set New Password</h4>
          </div>

          <div className="form-holder">
            <Form>
            <Form.Group className="form-field" controlId="">
                <Form.Label>Enter Your Password</Form.Label>
                <div className="password-field-holder">
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter your Password"
                  />
                  <div
                    className="eye-icon-holder"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="eye-icon"
                      icon={passwordVisible ? faEye : faEyeSlash}
                    />
                  </div>
                </div>
              </Form.Group>


              <Form.Group className="form-field" controlId="">
                <Form.Label>Confirm Password</Form.Label>
                <div className="password-field-holder">
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Confirm Password"
                  />
                  <div
                    className="eye-icon-holder"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon
                      className="eye-icon"
                      icon={passwordVisible ? faEye : faEyeSlash}
                    />
                  </div>
                </div>
              </Form.Group>

              <Button className="common-btn" onClick={handleSubmitClick}>Submit</Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>



      <Success_Modal
        text={"Password Reset Successfully"}
        show={successShow}
        onHide={() => setSuccessShow(false)}
      />
    </>
  );
}

export default Set_Password;
